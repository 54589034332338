<template>
    <div class="phone">
        <div class="mycart" v-if="cartList.length != 0">
            <div class="tabbox">
                <el-table :data="cartList" :header-cell-style="{fontSize:'24px'}">
                    <el-table-column prop="main_img" :label="$t('buyer.category.products')" width="140">
                        <template #default="scope">
                            <img class="product" :src="scope.row.main_img">
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_name" label="" width="210">
                        <template #default="scope">
                            <span class="miaosu">{{ scope.row.goods_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unit_price" :label="$t('buyer.orderInfo.price')" width="130">
                        <template #default="scope">
                            <span class="amount">{{ scope.row.unit_price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" :label="$t('buyer.orderDetail.quantity')" width="270">
                        <template #default="scope">
                            <div class="jiajian">
                                <span @click="jianChangr(scope.row)">-</span>
                                <input type="number" v-model="scope.row.count" @blur="jgChange(scope.row)">
                                <span @click="jiaChange(scope.row)">+</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_price" :label="$t('buyer.orderDetail.orderAmount')" width="120">
                        <template #default="scope">
                            <span class="total">{{ scope.row.total_price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" :label="$t('buyer.cart.delete')">
                        <template #default="scope">
                            <img class="shanchu" src="../../../assets/img/shanchu-bg-lan.png" @click="deleteChange(scope.row)">
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="subtotal">
                <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                <span>{{ allPrice }}</span>
            </div>
            <div class="dibubox">
                <div class="rightbox" @click="toshipping">
                    <span>{{ $t('buyer.cart.continueToShop') }}</span>
                </div>
                <div class="leftfanhui" @click="fanhui">
                    <img src="../../../assets/img/zuojiantou-lan.png">
                    <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                </div>
            </div>
        </div>
        <div v-else class="kongcart">
            <span>Your Cart is empty</span>
        </div>
    </div>
</template>

<script>
    import {cart,cartChange,cartDel} from '@/api/buyerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data() {
            return {
                cartList: [], //购物车列表
                allPrice: '', //总价格
            }
        },
        mounted(){
            this.getCart()
        },
        methods: {
            // 购物车
            async getCart(){
                const {data: res} = await cart()
                if(res.status == 200) {
                    this.cartList = res.success.list
                    this.allPrice = res.success.price_all
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 返回
            fanhui() {
                this.$router.push('/index')
            },
            // 购物车加减
            async cartChanges(cartid,count){
                const {data: res} = await cartChange({
                    cart_id: cartid,
                    count: count
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getCart()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 数量减少
            jianChangr(e){
                let count = Number(e.count)
                count--
                if(count <= 1) {
                   count = 1
                }
                this.cartChanges(e.id,count)
            },
            // 增加数量
            jiaChange(e){
                let count = Number(e.count)
                count++
                this.cartChanges(e.id,count)
            },
            // 输入价格
            jgChange(e) {
                let count = Number(e.count)
                if(count <= 1) {
                   count = 1
                }
                this.cartChanges(e.id,count)
            },
            // 删除该项
            async deleteChange(e) {
                const {data: res} = await cartDel({
                    cart_id: e.id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getCart()
                }else {
                    ElMessage.error(res.message) 
                }
            },
            // 下一步
            toshipping() {
                this.$router.push('/index/cart/shippinginfo')
            }
        }
    }
</script>


<style lang="less" scoped>
    .phone {
        .mycart {
            width: 100%;
            background: #fff;
            margin-top: 35px;
            padding-bottom: 30px;
            
            .tabbox {
                min-height: 200px;
                margin-top: 20px;
                .product {
                    width: 80px;
                    height: 80px;
                }

                .miaosu {
                    font-size: 18px;
                    -webkit-line-clamp: 2; //（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                    display: -webkit-box; //（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                    -webkit-box-orient: vertical; //（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                    overflow: hidden; //（文本溢出限定的宽度就隐藏内容）
                    text-overflow: ellipsis; //（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                }
                .amount {
                    font-size: 20px;
                }
                .jiajian {
                    span:nth-child(1) {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        background: #EEEEEE;
                        border-radius: 50%;
                        line-height: 40px;
                        text-align: center;
                        font-size: 30px;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    input{
                        display: inline-block;
                        height: 40px;
                        width: 60px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 28px;
                        border: none;
                        outline: none;
                    }

                    span:nth-child(3) {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        background: #D2D2D2;
                        border-radius: 50%;
                        line-height: 40px;
                        text-align: center;
                        font-size: 30px;
                        font-weight: 400;
                        cursor: pointer;
                    }
                }

                .total {
                    font-size: 20px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #00ADF1;
                }

                .shanchu {
                    width: 40px;
                    height: 40px;
                }
            }
            .subtotal {
                width: 100%;
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:nth-child(1) {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    margin-left: 50px;
                }

                span:nth-child(2) {
                    font-size: 28px;
                    font-family: Arial;
                    font-weight: bold;
                    margin-right: 50px;
                }
            }

            .dibubox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 30px;

                .leftfanhui {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #00ADF1;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 20px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .rightbox {
                    width: 50%;
                    height: 80px;
                    background: #00ADF1;
                    border-radius: 5px;
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                }
            }
        }
        .kongcart {
            width: 100%;
            height: 300px;
            background: #fff;
            text-align: center;
            line-height: 300px;
            margin-top: 30px;
            font-size: 40px;
            font-weight: 700;
        }
    }
</style>