<template>
    <div>
        <!-- 发送退款请求 -->
        <div class="bigbox">
            <div class="titlebox">
                <span>{{ $t('seller.orderDetail.sendrefundrequest') }}</span> 
            </div>
            <div class="nrbox">
                <span>{{ $t('seller.product.productName') }} *</span>
                <input type="text" disabled v-model="orderInfo.name"/>
            </div>
            <div class="nrbox">
                <span>{{ $t('seller.product.price') }} *</span>
                <input type="text" disabled v-model="orderInfo.price"/>
            </div>
            <div class="nrbox">
                <span>{{ $t('buyer.returnRequest.problemDescription') }} *</span>
                <textarea name="" id="" cols="30" rows="10" v-model="reason"></textarea>
            </div>
            <div class="updatebox" @click="tuikuanChange">
                <span>{{ $t('buyer.returnRequest.confirmation') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {orderRefund} from '@/api/buyerapi'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            orderInfo: {},
            reason: '', //退款原因
        }
    },
    mounted(){
        this.orderInfo = this.$route.query
    },
    methods: {
        // 退款
        async tuikuanChange(){
            const {data: res} = await orderRefund({
                id: this.orderInfo.id, //订单商品id
                refund_reason: this.reason //退款理由
            })
            if(res.status == 200) {
                ElMessage.success(res.message)
                this.$router.go(-1)
            }else {
                ElMessage.error(res.message)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bigbox {
    width: 100%;
    height: 500px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 30px;
    .titlebox {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #cacaca;
        line-height: 60px;
        text-align: left;
        font-size: 24px;
        font-family: Arial;
        font-weight: 600;
    }
    .nrbox {
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        input {
            width: 75%;
            height: 50px;
            background: #f6f6f6;
            border-radius: 10px;
            font-size: 20px;
            outline: none;
            border: none;
            box-sizing: border-box;
            padding-left: 20px;
        }
        span {
            display: block;
            width: 160px;
            text-align: left;
        }
        textarea {
            width: 75%;
            height: 150px;
            background: #f6f6f6;
            border-radius: 10px;
            font-size: 20px;
            outline: none;
            border: none;
            box-sizing: border-box;
            padding: 20px;
            overflow-y: hidden;
            resize: none;
        }
    }
    .updatebox {
        width: 280px;
        height: 50px;
        background: #00adf1;
        border-radius: 5px;
        font-size: 24px;
        font-family: Arial;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        margin: auto;
        margin-top: 30px;
    }
}
</style>