<template>
 <!-- 支付历史记录 -->
    <div class="phone">
        <div class='bodys'>
            <div class="allbox">
                <div class="title">
                    <span>{{ $t('seller.home.paymentHistory') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="created_at" :label="$t('seller.appliedRefundRequest.date')" width="300">
                            <template #default="scope">
                                <span>{{ scope.row.created_at }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="recharge_blance" :label="$t('seller.appliedRefundRequest.amount')">
                            <template #default="scope">
                                <span>{{ scope.row.recharge_blance }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="payment_type" :label="$t('seller.paymentHistory.paymentMethod')">
                            <template #default="scope">
                                <span>{{ scope.row.payment_type }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {paymentHistory} from "@/api/sellerapi"
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                page: 1, //页码
                count: 0,//总条数
                tableData: []
            }
        },
        mounted(){
            this.getpayment()
        },
        methods:{
            // 获取支付记录
            async getpayment(){
                const {data: res} = await paymentHistory({
                    page: this.page
                })
                if(res.status == 200) {
                    this.tableData = res.success.list
                    this.page = res.success.page
                    this.count = parseInt(res.success.count)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getpayment()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getpayment()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getpayment()
			}
        }
    }
</script>
<style lang="less" scoped>
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .title {
                    width: 100%;
                    height: 80px;
                    text-align: left;
                    line-height: 80px;
                    font-size: 24px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 2px solid #DFDFDF;
                }
                .tabbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    text-align: left;
                    margin-bottom: 30px;
                    span {
                        font-size: 20px;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 24px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 24px !important;
                    }
                }
            }
        }
    }
</style>