<template>
    <div class="bigbox">
        <div class="tickets">
            <span>Support Ticket</span>
        </div>
        <div class="createbox" @click="tcshow = true">
            <span>+</span>
            <span>Create a Ticket</span>
        </div>
        <div class="tabbox">
            <div class="tabtitle">
                <span>Tickets</span>
            </div>
            <div class="tabitem">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="tikcet" label="Ticket ID" width="300" />
                    <el-table-column prop="sending" label="Sending Date" width="310" />
                    <el-table-column prop="subject" label="Subject" width="300" />
                    <el-table-column prop="status" label="Status" width="300">
                        <template #default="scope">
                            <span class="span1">{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="options" :label="$t('seller.myLoan.options')" width="300">
                        <template #default="scope">
                            <span class="span2" @click="toDetails">{{ scope.row.options }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="fenye">
            <el-pagination background layout="prev, pager, next" :total="1000" />
        </div>
        <!-- 弹窗 -->
        <div class="tczhezhao" v-if="tcshow">
            <div class="tcbox">
                <div class="btticket">
                    <span>Create a Ticket</span>
                </div>
                <div class="subjbox">
                    <span>Subject</span>
                    <input type="text" placeholder="Subject">
                </div>
                <div class="areabox">
                    <span>Providea detailed description</span>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="photobox">
                    <span>Photo</span>
                    <div class="updateimg">
                        <el-upload
                            v-model:file-list="fileList"
                            class="upload-demo"
                            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                            multiple
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :limit="3"
                            :on-exceed="handleExceed"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
                <div class="btnbox">
                    <span class="span01">Cancel</span>
                    <span class="span02">Send Ticket</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            fileList: [], //上传的图片
            tcshow: false, //弹窗
            tableData: [
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'},
                {tikcet: '#2147483647 ',sending: '2023-07-03 08:56:15',subject: '仨的阿瑟 ',status: 'Pending',options: 'View Details'}
            ]
        }
    },
    methods:{
        // 查看详情
        toDetails(){
            this.$router.push('/Home/ViewDetails')
        }
    }
}
</script>
<style lang="less" scoped>
</style>