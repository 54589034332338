<template>
    <div class="bigbox">
        <div class="dhtitle">
            <span>Conversations With</span>
            <span>{{ sllerEmail }}</span>
        </div>
        <div class="dhbombox">
            <div class="dhwenzi">
                <span>{{ title }}</span>
            </div>
            <div class="sendbox">
                <div v-for="item in contentList" :key="item.id">
                    <div class="headbox">
                        <div class="headimg">
                            <img :src="item.member_head"/>
                        </div>
                        <div class="namebox">
                            <span>{{ item.email }}</span>
                            <span>{{ item.created_at }}</span>
                        </div>
                    </div>
                    <div class="tishi">
                        <span>{{ item.content }}</span>
                    </div>
                </div>
                <div class="wenbeny">
                    <textarea v-model="content"></textarea>
                </div>
                <div class="sends">
                    <span @click="addContent">{{ $t('seller.withdrawToWallet.send') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {huiContentList,contentAdd} from '@/api/buyerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data(){
            return {
                id: '', //会话id
                contentList: [], //会话内容列表
                title: '', //标题
                content: '', //添加的内容
                sllerEmail: '',
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.gethuih()
        },
        methods: {
            // 获取会话内容列表
            async gethuih(){
                const {data: res} = await huiContentList({
                    id: this.id
                })
                if(res.status == 200) {
                    this.contentList = res.success.list
                    this.title = res.success.title
                    this.sllerEmail = res.success.sller_email
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 添加会话内容
            async addContent(){
                const {data: res} = await contentAdd({
                    id: this.id,
                    content: this.content
                })
                this.content = ''
                if(res.status == 200) {
                    this.gethuih()
                    ElMessage.success(res.message)
                }else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.bigbox {
    width: 100%;

    .dhtitle {
        font-size: 24px;
        font-family: Arial;
        font-weight: 600;
        text-align: left;
        line-height: 60px;
        box-sizing: border-box;
        padding: 0 20px;
        span:nth-child(2) {
            color: #00adf1;
            margin-left: 5px;
        }
    }

    .dhbombox {
        background: #fff;

        .dhwenzi {
            width: 100%;
            padding: 20px 30px;
            border-bottom: solid 1px #cacaca;
            margin-top: 15px;
            font-size: 18px;
            font-weight: 600;
            font-family: Arial;
            text-align: left;
            box-sizing: border-box;
        }

        .sendbox {
            width: 100%;

            .headbox {
                display: flex;
                align-items: center;
                margin-left: 30px;
                margin-top: 35px;

                .headimg {
                    width: 66px;
                    height: 66px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .namebox {
                    display: flex;
                    flex-direction: column;
                    align-items: self-start;
                    margin-left: 25px;

                    span:nth-child(1) {
                        font-size: 24px;
                        font-family: Adobe Heiti Std;
                        font-weight: normal;
                    }

                    span:nth-child(2) {
                        font-size: 18px;
                        color: #969696;
                        margin-top: 4px;
                    }
                }
            }

            .tishi {
                font-size: 18px;
                font-family: Arial;
                font-weight: 400;
                color: #969696;
                text-align: left;
                margin-left: 120px;
                margin-top: 30px;
            }

            .wenbeny {
                width: 100%;
                height: 141px;
                box-sizing: border-box;
                padding: 0 30px;
                margin-top: 25px;
                font-size: 18px;

                textarea {
                    width: 100%;
                    height: 100%;
                    border: 2px solid #e4e4e4;
                    border-radius: 3px;
                    resize: none;
                    outline: none;
                    box-sizing: border-box;
                    padding: 15px;
                }
            }

            .sends {
                display: flex;
                justify-content: flex-end;

                span {
                    display: inline-block;
                    width: 160px;
                    height: 50px;
                    line-height: 50px;
                    background: #00adf1;
                    border-radius: 5px;
                    font-size: 20px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #ffffff;
                    margin-right: 30px;
                    margin-top: 15px;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}</style>