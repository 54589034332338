<template>
    <div class="phone">
        <div class="allbox">
            <div class="titles">
                <span>{{ $t('seller.home.Dashboard') }}</span>
            </div>
            <div class="sibox">
                <div class="itemone">
                    <div class="lefttext">
                        <span>{{ $t('seller.home.products') }}</span>
                        <span>{{ products }}</span>
                    </div>
                    <div class="imgbox">
                        <img src="../../../assets/img/seller-chanping-bai.png">
                    </div>
                </div>
                <div class="itemone">
                    <div class="lefttext">
                        <span>{{ $t('agent.seller.shopRating') }}</span>
                        <span>{{ rating }}</span>
                        <span>{{ $t('seller.home.credibility') }}</span>
                        <span>{{ credibility }}</span>
                        <span>{{ $t('seller.home.creditLimit') }}</span>
                        <span>{{ creditLimit }}</span>
                    </div>
                    <div class="imgbox">
                        <img src="../../../assets/img/seller-xingxing-bai.png">
                    </div>
                </div>
                <div class="itemone">
                    <div class="lefttext">
                        <span>{{ $t('seller.home.totalOrders') }}</span>
                        <span>{{ totalOrder }}</span>
                    </div>
                    <div class="imgbox">
                        <img src="../../../assets/img/seller-wenjian-bai.png">
                    </div>
                </div>
                <div class="itemone">
                    <div class="lefttext">
                        <span>{{ $t('seller.home.totalSales') }}</span>
                        <span>{{ totalSales }}</span>
                        <span>{{ $t('seller.home.totalCommission') }}</span>
                        <span>{{ totalCommission }}</span>
                    </div>
                    <div class="imgbox">
                        <img src="../../../assets/img/seller-shuju-bai.png">
                    </div>
                </div>
            </div>
            <div class="wubox">
                <div class="itemonebox">
                    <span>{{ $t('seller.home.soldAmount') }}</span>
                    <span>{{ $t('seller.home.yourSoldAmount') }}</span>
                    <span>{{ current_month_amount }}</span>
                    <span>{{ $t('seller.home.commissionCurrentMonth') }}</span>
                    <span>{{ month_amount }}</span>
                    <span>{{ $t('seller.home.lastMonthAmount') }}: {{ last_month_amount }}</span>
                    <span>{{ $t('seller.home.lastMonthCommission') }}: {{ last_month_commission }}</span>
                </div>
                <div class="itemtwobox">
                    <div class="title">
                        <span>{{ $t('seller.home.categoryWiseProductCount') }}</span>
                    </div>
                    <div v-for="item in cateGoodsList" :key="item.goods_count">
                        <div class="itemtext">
                            <span>{{ item.cate_name }}</span>                           
                            <span>{{ item.goods_count }}</span>
                        </div>
                    </div>
                </div>
                <div class="itemsanbox">
                    <div class="orders">
                        <span>{{ $t('seller.home.order') }}</span>
                        <span>{{ $t('seller.home.thisMonth') }}</span>
                    </div>
                    <div class="derbox">
                        <div class="myxbox">
                            <img src="../../../assets/img/seller-gouwudai-lan.png">
                            <div class="ycwenz">
                                <span>{{ $t('seller.home.newOrder') }}</span>
                                <span>{{ orders.new_order }}</span>
                            </div>
                        </div>
                        <div class="myxbox">
                            <img src="../../../assets/img/seller-cancelled-lan.png">
                            <div class="ycwenz">
                                <span>{{ $t('seller.home.cancelled') }}</span>
                                <span>{{ orders.cancelled }}</span>
                            </div>
                        </div>
                        <div class="myxbox">
                            <img src="../../../assets/img/seller-delivery-lan.png">
                            <div class="ycwenz">
                                <span>{{ $t('seller.home.onDelivery') }}</span>
                                <span>{{ orders.on_delivery }}</span>
                            </div>
                        </div>
                        <div class="myxbox">
                            <img src="../../../assets/img/seller-delivered-lan.png">
                            <div class="ycwenz">
                                <span>{{ $t('seller.home.delivered') }}</span>
                                <span>{{ orders.delivered }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="itemsibox">
                    <img :src="verified">
                </div>
            </div>
            <div class="moneybox">
                <div class="itemmoney" @click="toMoneyWidthdraw">
                    <span>{{ $t('seller.home.moneyWithdraw') }}</span>
                    <img src="../../../assets/img/seller-moneyicon-lan.png">
                </div>
                <div class="itemmoney" @click="toProduct">
                    <span>{{ $t('seller.product.addNewProduct') }}</span>
                    <img src="../../../assets/img/seller-addicon-lan.png">
                </div>
                <div class="shopset">
                    <span class="span1">{{ $t('seller.home.shopSetting') }}</span>
                    <img src="../../../assets/img/seller-shopicon-lan.png">
                    <span class="span2" @click="toSeeting">{{ $t('seller.home.goToSetting') }}</span>
                </div>
                <div class="shopset">
                    <span class="span1">{{ $t('seller.home.paymentSetting') }}</span>
                    <img src="../../../assets/img/seller-paymenticon-lan.png">
                    <span class="span2" @click="toConfigure">{{ $t('seller.home.configureNow') }}</span>
                </div>
            </div>
            <div class="top12">
                <div class="topbox">
                    <span>Top 12 Products</span>
                </div>
                <div class="scroll_list">
                    <div class="itemcp" v-for="item in goodsList" :key="item.id" @click="toshopDetail(item.id)">
                        <img :src="item.main_img" />
                        <div class="jiage">
                            <text>{{ item.primary }}</text>
                            <el-rate v-model="item.grade" disabled />
                            <div class="mswenz">{{ item.goods_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {sellerDashboard,sellerTopgoods} from '@/api/sellerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data(){
            return {
                products: '', //统计
                rating: '', //评分
                credibility: '',//可靠性
                creditLimit: '', //信贷额度
                totalOrder: '', //总订单数
                totalSales: '', //销售总额
                totalCommission: '', //总佣金
                cateGoodsList: [],
                orders: [], //订单
                verified: '', //身份认证图标
                current_month_amount: '',
                last_month_amount: '',
                month_amount: '',
                last_month_commission: '',
                goodsList: [], //12大产品
                xingxing: 3
            }
        },
        mounted(){
            this.sellerDashboard()
            this.getTopGoods()
        },
        methods: {
            // 设置店铺
            toSeeting(){
                this.$router.push('/Home/SellerShopSetting')
            },
            // 管理配置文件
            toConfigure(){
                this.$router.push('/Home/SellerManageProfile')
            },
            toMoneyWidthdraw(){
                this.$router.push('/Home/SellerMoneyWithdraw')
            },
            toProduct(){
                this.$router.push('/Home/SellerProducts')
            },
            // 获取数据
            async sellerDashboard(){
                const {data :res} = await sellerDashboard()
                if(res.status == 200) {
                    this.products = res.success.products
                    this.rating = res.success.shop_rating
                    this.credibility = res.success.line_credit
                    this.creditLimit = res.success.loan_total
                    this.totalOrder = res.success.order_count
                    this.totalSales = res.success.total_amount
                    this.totalCommission = res.success.total_commission
                    this.cateGoodsList = res.success.cate_goods
                    this.orders = res.success.orders
                    this.verified = res.success.verified
                    this.current_month_amount = res.success.current_month_amount
                    this.last_month_amount = res.success.last_month_amount
                    this.month_amount = res.success.month_amount
                    this.last_month_commission = res.success.last_month_commission
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 获取12大产品
            async getTopGoods(){
                const {data :res} = await sellerTopgoods()
                if(res.status == 200) {
                    this.goodsList = res.success
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 去商品详情
            toshopDetail(id) {
                this.$router.push({path: '/index/shopdetail', query: {id:id}})
            }
        }
    }
</script>


<style lang="less" scoped>
    .phone {
        .allbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            background: #f6f6f6;
            .titles {
                width: 100%;
                font-size: 24px;
                font-family: Arial;
                color: #0277BD;
                text-align: left;
                margin-top: 30px;
                font-weight: 600;
            }
            .sibox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .itemone {
                    width: 49%;
                    margin-top: 20px;
                    height: 340px;
                    background: #0277BD;
                    border-radius: 5px;
                    font-size: 22px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 20px;
                    .lefttext {
                        flex-shrink: 0;
                        text-align: left;
                        span {
                            display: block;
                        }
                    }
                    .imgbox {
                        img {
                            width: 80px;
                            height: 80px; 
                        }
                    }
                }
            }
            .wubox {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 20px;
                .itemonebox {
                    width: 49%;
                    background: #F0FAFE;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    padding: 20px;
                    text-align: left;
                    span:nth-child(1){
                        font-size: 20px;
                        color: #0277BD;
                        margin-top: 35px;
                    }
                    span:nth-child(2){
                        font-size: 18px;
                        color: #969696;
                        margin-top: 30px;
                    }
                    span:nth-child(3){
                        font-size: 18px;
                        color: #0277BD;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    span:nth-child(4){
                        font-size: 18px;
                        color: #969696;
                        margin-top: 30px;
                    }
                    span:nth-child(5){
                        font-size: 18px;
                        color: #0277BD;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    span:nth-child(6){
                        font-size: 18px;
                        color: #969696;
                        margin-top: 30px;
                    }
                    span:nth-child(7){
                        font-size: 18px;
                        color: #969696;
                        margin-top: 30px;
                    }
                }
                .itemtwobox {
                    width: 49%;
                    color: #0277BD;
                    box-sizing: border-box;
                    padding: 0 20px;
                    background: #fff;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    .title {
                        width: 100%;
                        height: 60px;
                        font-size: 20px;
                        line-height: 60px;
                        text-align: left;
                        border-bottom: solid 1px #dfdfdf;
                    }
                    .itemtext {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 18px;
                        color: #0277BD;
                    }
                }
                .itemsanbox {
                    width: 49%;
                    color: #0277BD;
                    box-sizing: border-box;
                    padding: 0 35px;
                    background: #fff;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    margin-top: 20px;
                    .orders {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 85px;
                        align-items: flex-start;
                        justify-content: flex-end;
                        border-bottom: solid 1px #dfdfdf;
                        box-sizing: border-box;
                        padding-bottom: 10px;
                        span:nth-child(1){
                            font-size: 20px;
                            color: #0277BD;
                        }
                        span:nth-child(2){
                            font-size: 20px;
                            color: #969696;
                        }
                    }
                    .derbox {
                        width: 100%;
                        margin-top: 20px;
                        .myxbox {
                            display: flex;
                            height: 70px;
                            align-items: center;
                            img {
                                width: 30px;
                                height: 30px;
                            }
                            .ycwenz {
                                display: flex;
                                flex-direction: column;
                                text-align: left;
                                margin-left: 20px;
                                span:nth-child(1){
                                    font-size: 20px;
                                }
                                span:nth-child(2) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
                .itemsibox {
                    width: 49%;
                    color: #0277BD;
                    box-sizing: border-box;
                    background: #fff;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    margin-top: 20px;
                    img {
                        width: 100%;
                        height: auto;
                        margin-top: 47%; 
                    }
                }
            }
            .moneybox {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 20px;
                margin-bottom: 20px;
                .itemmoney {
                    width: 49%;
                    height: 190px;
                    background: #F0FAFE;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    span {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #0277BD;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                        margin-top: 25px;
                    }
                }
                .itemmoney:nth-child(2){
                    background: #fff;
                }
                .shopset {
                    width: 49%;
                    height: 190px;
                    background: #F0FAFE;
                    border: 1px solid #ECECEC;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    .span1 {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #0277BD;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                        margin-top: 15px;
                    }
                    .span2 {
                        display: block;
                        width: 60%;
                        height: 50px;
                        background: #0277BD;
                        border: 1px solid #ECECEC;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 50px;
                        font-size: 22px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                }
            }
            .top12 {
                background: #fff;
                margin-bottom: 20px;
                .topbox {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #0277BD;
                    text-align: left;
                    height: 60px;
                    line-height: 60px;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .scroll_list {
                    width: 100%;
                    overflow: auto;
                    display: flex;
                    .itemcp {
                        width: 320px;
                        padding: 0 20px;
                        display: flex;
                        flex-shrink: 0;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        cursor: pointer;
                        img {
                            width: 70%;
                            height: 55%;
                        }
                        .jiage {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-top: 10px;
                            box-sizing: border-box;
                            padding-left: 10%;
                            & > text {
                                font-size: 24px;
                                font-family: Arial;
                                font-weight: bold;
                                color: #53bef4;
                        }
                            .mswenz {
                                width: 100%;
                                height: 43px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                font-size: 18px;
                                -webkit-box-orient: vertical;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
</style>