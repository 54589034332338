<template>
    <div class="phone">
        <div class="allbox">
            <div class="leftnav" v-if="caidanPhone">
                <div class="btnmune">
                    <div class="logobox">
                        <img :src="logo">
                    </div>
                    <div class="uname">
                        <span>{{ sellerinfo.member_name }}</span>
                        <span>{{ sellerinfo.member_phone }}</span>
                    </div>
                    <router-link to="/Home/SellerDashboard" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left1.png">
                        <span>{{ $t('seller.home.Dashboard') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerProducts" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left2.png">
                        <span>{{ $t('seller.home.products') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerOrders" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left5.png">
                        <span>{{ $t('seller.order.order') }}</span>
                        <span class="numbers">{{ orderNumber }}</span>
                    </router-link>
                    <router-link to="/Home/SellerRefundRequests" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left6.png">
                        <span>{{ $t('seller.home.receivedRefundRequest') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerShopSetting" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left7.png">
                        <span>{{ $t('seller.home.shopSetting') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerPaymentHistory" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left8.png">
                        <span>{{ $t('seller.home.paymentHistory') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerMyWallet"  class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left9.png">
                        <span>{{ $t('seller.home.mayWallet') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerMoneyWithdraw" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left10.png">
                        <span>{{ $t('seller.home.moneyWithdraw') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerCommissionHistory" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left11.png">
                        <span>{{ $t('seller.home.commissionHistory') }}</span>
                    </router-link>
                    <router-link to="/Home/SellerConversations" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left12.png">
                        <span>{{ $t('seller.home.conversations') }}</span>
                        <span class="numbers">{{ count }}</span>
                    </router-link>
                    <router-link to="/Home/SellerMyLoan" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left14.png">
                        <span>{{ $t('seller.home.myLoan') }}</span>
                    </router-link>
                    <router-link to="/Home/LoanRequestHistories" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left14.png">
                        <span>{{ $t('seller.home.loanRequest') }}</span>
                    </router-link>
                    <router-link to="/Home/LoanQuotaRequestHistories" class="itembox" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-left14.png">
                        <span>{{ $t('seller.home.loanQuotaRequest') }}</span>
                    </router-link>
                </div>
                <div class="heibox" @click="caidanPhone = !caidanPhone"></div>
            </div>
            <div class="rightbox">
                <div class="righttop">
                    <div class="cdbox">
                        <img src="../../assets/img/seller-caidan-hui.png" @click="caidanPhone = !caidanPhone">
                        <img src="../../assets/img/seller-diqiu-hui.png" @click="toindex">
                    </div>
                    <div class="ycbox">
                        <div class="xiaoxibox" @click="xiaoxishow = !xiaoxishow">
                            <span v-if="xiaoxiList.length != 0"></span>
                            <img src="../../assets/img/seller-xiaoxi-hui.png">
                        </div>
                        <img :src="language_img" @click="yuyanshow = !yuyanshow">
                        <!-- 头像 -->
                        <div class="userbox" @click="txtc = !txtc">
                            <img :src="sellerinfo.member_head">
                            <div class="namebox">
                                <span>{{ sellerinfo.member_name }}</span>
                                <span>{{ sellerinfo.member_phone }}</span>
                            </div>
                            <div class="txtc" v-if="txtc">
                                <div class="tcitem" @click="toConfigure">
                                    <img src="../../assets/img/seller-wode-hui.png">
                                    <span>{{ $t('seller.mangeProfile.mangeProfile') }}</span>
                                </div>
                                <div class="tcitem" @click="logout">
                                    <img src="../../assets/img/seller-tuichu-hui.png">
                                    <span>{{ $t('seller.home.logOut') }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 语言选择框 -->
                        <div class="xzyuyan" v-if="yuyanshow">
                            <div v-for="(item,index) in languageList" :key="index">
                                <div class="itemyuyan" @click="languageChange(item)">
                                    <img :src="item.language_img" />
                                    <span>{{ item.language_name }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 消息弹窗 -->
                        <div class="xxtc" v-if="xiaoxishow">
                            <div class="tongzhi">Notifications</div>
                            <div class="ordbigbox">
                                <div class="orderbox" v-for="(item,index) in xiaoxiList" :key="index">
                                    <span>{{ item.order_no }}</span>
                                    <span>{{ item.created_at }}</span>
                                </div>
                            </div>
                            <div class="xiansbox" @click="toOrder">View AlI Notifications</div>
                        </div>
                    </div>
                </div>
                <div class="rightcent">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <TabBarSeller></TabBarSeller>
    </div>
</template>

<script>
    import TabBarSeller from '@/components/tabBarSeller.vue'
    import { language,footInfo } from '../../api/api.js'
    import {sellerInfo,huihtongj,orderCount,ordernewlist} from '@/api/sellerapi'
    export default {
        components: {
            TabBarSeller
        },
        data(){
            return {
                caidan: true, //控制菜单是否显示
                caidanPhone: false, //手机端菜单显示
                txtc: false, //头像弹窗
                yuyanshow: false, //是否显示语言弹窗
                xiaoxishow: false, //消息显示
                languageList: [], //语言列表
                language_img: '', //语言图标
                language_name: '', //语种
                sellerinfo: {}, //卖家基本信息
                logo: '', //logo
                title: '',
                count: '',
                orderNumber: '',
                xiaoxiList: [], //消息列表
            }
        },
        mounted(){
            this.getLanguage()
            this.getSellerInfo()
            this.getlogo()
            this.gettongji()
            this.orderCounts()
            this.getxiaoxi()
        },
        methods: {
            // 语言切换事件
            languageChange(e){
                this.yuyanshow = false
                this.language_img = e.language_img
                this.language_name = e.language_name
                sessionStorage.setItem('language',e.values)
                sessionStorage.setItem('language_name',e.language_name)
                sessionStorage.setItem('language_img',e.language_img)
                this.$router.go(0)
                this.$i18n.locale = sessionStorage.getItem('language')
            },
            // 获取语言
            async getLanguage() {
                const { data: res } = await language()
                if (res.status == 200) {
                    this.languageList = res.success
                    if(sessionStorage.getItem('language')){
                        this.language_img = sessionStorage.getItem('language_img')
                        this.language_name = sessionStorage.getItem('language_name')
                    }else{
                        this.language_img = res.success[0].language_img
                        this.language_name = res.success[0].language_name
                    }
                } else {
                    alert(res.message)
                }
                // console.log(res)
            },
            // 获取消息通知
            async getxiaoxi(){
                const {data: res} = await ordernewlist()
                this.xiaoxiList = res.success
                console.log('消息通知',res)
            },
            // 获取卖家信息
            async getSellerInfo(){
                const {data: res} = await sellerInfo()
                if(res.status == 200) {
                    this.sellerinfo = res.success.basic_info
                    console.log(res)
                }
            },
            // 获取会话统计
            async gettongji(){
				const {data: res} = await huihtongj()
				this.count = res.success.count
				// console.log('aaaaaaaa',res)
			},
            // 订单数量统计
            async orderCounts(){
				const {data: res} = await orderCount()
				this.orderNumber = res.success.count
				// console.log('aaaaaaaa',res)
			},
            // 获取logo
            async getlogo(){
                const {data: res} = await footInfo()
                if(res.status == 200) {
                    this.logo = res.success.logo
                    this.title = res.success.website_title
                    console.log(res)
                }
            },
            // 去首页
            toindex(){
                this.$router.push('/index')
            },
            // 去订单页面
            toOrder() {
                this.xiaoxishow = false
                this.$router.push('/Home/SellerOrders')
            },
            // 管理配置文件
            toConfigure(){
                this.$router.push('/Home/SellerManageProfile')
            },
            // 退出登录
            logout(){
                sessionStorage.removeItem('member_token')
                sessionStorage.removeItem('member_role')
                sessionStorage.removeItem('language')
                sessionStorage.removeItem('language_name')
                sessionStorage.removeItem('language_img')
                this.$router.push('/index')
            }
        }
    }
</script>

<style lang="less" scoped>
    .phone {
        .allbox {
            width: 100%;
            margin: auto;
            display: flex;
            overflow: hidden;
            background: #fff;
            .leftnav {
                width: 100%;
                height: 100%;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 999;
                display: flex;
                .btnmune {
                    width: 400px;
                    height: 100%;
                    background: #081b22;
                    .logobox {
                        width: 400px;
                        img {
                            width: 200px;
                            height: auto;
                            margin: auto;
                            margin-top: 25px;
                        }
                    }
                    .uname {
                        font-size: 22px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #F9FBFD;
                        display: flex;
                        flex-direction: column;
                        margin-top: 25px;
                        margin-bottom: 30px;
                        span:nth-child(2) {
                            font-size: 26px;
                        }
                    }
                    .itembox:hover {
                        background: #006CAC;
                    }
                    .itembox.router-link-active {
                        background: #006CAC;
                    }
                    .itembox {
                        display: flex;
                        align-items: center;
                        width: 400px;
                        height: 50px;
                        box-sizing: border-box;
                        padding-left: 30px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #F9FBFD;
                        cursor: pointer;
                        text-decoration: none;
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                        }
                        .numbers {
                            margin-left: 20px;
                            display: inline-block;
                            padding: 2px 10px;
                            border-radius: 4px;
                            margin-top: 5px;
                            background: #0abb75;
                        }
                    }
                }
                .heibox {
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.5);
                }
            }
            .rightbox {
                width: 96%;
                height: 100%;
                margin: auto;
                position: relative;
                padding-bottom: 150px;
                .righttop {
                    height: 90px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    border-bottom: solid 2px #dcdcdc;
                    .cdbox {
                        display: flex;
                        align-items: center;
                        img:nth-child(1){
                            width: 40px;
                        }
                        img:nth-child(2){
                            width: 50px;
                            margin-left: 30px;
                        }
                    }
                    .ycbox {
                        display: flex;
                        align-items: center;
                        position: relative;
                        .xiaoxibox {
                            position: relative;
                            span {
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                background: #00adf1;
                                position: absolute;
                                top: -6px;
                                right: -3px;
                            }
                            img{
                                width: 40px;
                            }
                        }
                        &>img:nth-child(2){
                            width: 40px;
                            margin-left: 40px;
                        }
                        .userbox {
                            display: flex;
                            align-items: center;
                            margin-left: 40px;
                            position: relative;
                            &>img {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                            .namebox {
                                display: none;
                                flex-direction: column;
                                text-align: left;
                                margin-left: 10px;
                                span:nth-child(1){
                                    font-size: 24px;
                                }
                                span:nth-child(2){
                                    font-size: 18px;
                                    color: #969696;
                                }
                            }
                            .txtc {
                                width: 260px;
                                min-height: 60px;
                                background: #FFFFFF;
                                border: 1px solid #DFDFDF;
                                border-radius: 4px;
                                position: absolute;
                                top: 80px;
                                right: 20px;
                                .tcitem {
                                    width: 100%;
                                    height: 60px;
                                    line-height: 60px;
                                    font-size: 24px;
                                    color: #969696;
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 30px;
                                        height: 30px;
                                        margin-top:-3px;
                                        margin-left: 10px;
                                        margin-right: 10px;
                                    }
                                }
                                .tcitem:hover {
                                    background: #0277BD;
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .xzyuyan {
                            width: 240px;
                            position: absolute;
                            top: 55px;
                            left: -40px;
                            border-radius: 5px;
                            overflow: hidden;
                            z-index: 999;
                            border: solid 1px #e6e6e6;
                            .itemyuyan:hover {
                                background: #00adf1;
                                color: #fff;
                                cursor: pointer;
                            }
                            .itemyuyan {
                                background: #fff;
                                width: 100%;
                                height: 60px;
                                box-sizing: border-box;
                                padding-left: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                            img {
                                width: 30px;
                                margin-right: 15px;
                            }
                                font-size: 24px;
                                color: #969696;
                            }
                        }
                        .xxtc {
                            width: 300px;
                            position: absolute;
                            z-index: 999;
                            top: 55px;
                            left: -300px;
                            background: #fff;
                            border: solid 1px #e6e6e6;
                            border-radius: 5px;
                            .tongzhi {
                                width: 100%;
                                height: 60px;
                                background: #f2f3f8;
                                font-size: 20px;
                                font-weight: 600;
                                text-align: left;
                                line-height: 60px;
                                box-sizing: border-box;
                                padding-left: 10px;
                            }
                            .ordbigbox {
                                width: 100%;
                                max-height: 200px;
                                overflow: auto;
                                .orderbox {
                                    width: 100%;
                                    text-align: left;
                                    box-sizing: border-box;
                                    padding: 10px;
                                    font-size: 16px;
                                    line-height: 20px;
                                    line-height: 20px;
                                    span:nth-child(2){
                                        font-size: 14px;
                                        color: #969696;
                                    }
                                }
                            }
                            .xiansbox {
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                border-top: solid 1px #e6e6e6;
                                cursor: pointer;
                                font-size: 20px;
                            }
                        }
                    }
                }
                .rightcent {
                    width: 100%;
                }
                .rightbotm {
                    width: 100%;
                    height: 70px;
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #969696;
                    background: #fff;
                    text-align: center;
                    line-height: 70px;
                    position: absolute;
                    bottom: 0;
                    border-top: solid 1px #dcdcdc;
                }
            }
        }
    }
</style>