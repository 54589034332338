<template>
    <div class="phone">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('buyer.myWallet.myWallet') }}</span>
            </div>
            <div class="moneybox">
                <div class="leftmoney">
                    <img src="../../assets/img/meiyuan.png"/>
                    <div class="jinebox">
                        <span>{{ balance }}</span>
                        <span>{{ $t('buyer.myWallet.walletBalance') }}</span>
                    </div>
                </div>
                <div class="rightmoney" @click="showpaytc">
                    <img src="../../assets/img/tianjia.png"/>
                    <div class="righttext">
                        <span>{{ $t('buyer.myWallet.cryptoWalletRecharge') }}</span>
                    </div>
                </div>
            </div>
            <div class="walletbsbg">
                <div class="walletbox">
                    <span>{{ $t('buyer.myWallet.walletRechargeHistory') }}</span>
                </div>
                <div class="tabbox" v-for="item in tableData" :key="item.id">
                    <div class="itemtable">
                        <p>{{ $t('buyer.myWallet.date') }}</p>
                        <div class="neir">
                            <span class="spans" @click="toDetail(item.id)">{{ item.created_at }}</span>
                        </div>
                    </div>
                    <div class="itemtable">
                        <p>{{ $t('buyer.myWallet.amount') }}</p>
                        <div class="neir">
                            <span>{{ item.conversion_amount }}</span>
                        </div>
                    </div>
                    <div class="itemtable">
                        <p>{{ $t('buyer.myWallet.paymentMethod') }}</p>
                        <div class="neir">
                            <span>{{ item.payment_name }}</span>
                        </div>
                    </div>
                    <div class="itemtable">
                        <p>{{ $t('buyer.myWallet.paymentAddress') }}</p>
                        <div class="neir">
                            <span>{{ item.payment_address }}</span>
                        </div>
                    </div>
                    <div class="itemtable">
                        <p>{{ $t('buyer.myWallet.approval') }}</p>
                        <div class="neir">
                            <span class="span2">{{ item.status }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 弹窗 -->
            <div class="paytc" @click="guanbiTc" v-show="showtc">
                <div class="tchezi" id="tchezi">
                    <div class="paytitle">
                        <span>{{ $t('buyer.myWallet.cryptoWalletRecharge') }}</span>
                    </div>
                    <div class="payselectbox">
                        <div v-for="item in peyList" :key="item.id">
                            <div class="payselect" :class="payid == item.id? 'dongtai' : ''" @click="seletcPay(item.id)">
                                <img :src="item.payment_img" />
                                <span>{{ item.payment_name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="zhifuma">
                        <div class="adresbox">
                            <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                            <span @click="copyText">{{ $t('seller.product.copy') }}</span>
                            <span>{{ payAddress }}</span>
                        </div>
                        <div class="qrcode">
                            <span class="qrtext">{{ $t('seller.makeCryptoPayment.qrCode') }}</span>
                            <div class="ewmbox">
                                <div class="erweima">
                                    <img :src="qrCode" />
                                </div>
                                <div class="addrbox">
                                    <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                                    <span>{{ payAddress }}</span>
                                </div>
                            </div>
                            <div class="miaosu">
                                <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                                <input type="text" :placeholder="describe" v-model="describes" />
                            </div>
                        </div>
                    </div>
                    <div class="amountbox">
                        <div>
                            <span>{{ $t('seller.makeCryptoPayment.amount') }}</span>
                            <span>*</span>
                        </div>
                        <input type="number" placeholder="0"  v-model="amount"/>
                    </div>
                    <div class="qrbtn">
                        <div class="querenbox" @click="confirm">
                            <span>{{ $t('agent.sellerOrder.confirm') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {paymentMethod} from '@/api/api'
import {buyerBalance,rechargeList,rechargeAdd} from '@/api/buyerapi'
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            showtc: false, //支付弹窗
            balance: '', //余额
            tableData: [], //充值记录列表
            peyList: [], //支付方式列表
            payid: '', //支付方式id
            qrCode: '', //二维码
            payAddress: '',//支付地址
            describe: '', //描述
            describes: '', //输入的描述
            amount: '', //金额
        }
    },
    mounted(){
        this.getBalance()
        this.getRecharge()
        this.getPayMethod()
    },
    methods: {
        // 获取钱包余额
        async getBalance(){
            const {data: res} = await buyerBalance()
            if(res.status == 200) {
                this.balance = res.success.balance
                console.log(res)
            }else {
                ElMessage.error(res.message)
            }
        },
        // 充值记录列表
        async getRecharge(){
            const {data: res} = await rechargeList()
            if(res.status == 200) {
                this.tableData = res.success
                // console.log(res)
            }else {
                ElMessage.error(res.message)
            }
        },
        // 获取支付方式
        async getPayMethod(){
            const {data: res} = await paymentMethod()
            this.peyList = res.success
            this.payid = res.success[0].id
            this.qrCode = res.success[0].qr_code
            this.payAddress = res.success[0].payment_address
            this.describe = res.success[0].describe
            console.log(res)
        },
         // 选择支付方式
         seletcPay(payid){
            this.payid = payid
            for(var i = 0; i < this.peyList.length; i++) {
                if(payid == this.peyList[i].id) {
                    this.qrCode = this.peyList[i].qr_code
                    this.payAddress = this.peyList[i].payment_address
                    this.describe = this.peyList[i].describe
                }
            }
        },
        // 复制地址
        copyText(){
            this.showtc = false
            this.$copyText(this.payAddress).then((e)=>{
                ElMessage.success('Copy Success')
            })
        },
        // 显示支付弹窗
        showpaytc() {
            this.showtc = true
        },
        // 遮罩层显示隐藏
        guanbiTc(e){
            if(e.currentTarget === e.target) {
                this.showtc = false
                this.balance = ''
                this.amount = ''
            }
        },
        // 确认支付
        async confirm(){
            this.showtc = false
            const {data: res} = await rechargeAdd({
                payment_id: this.payid, 
                recharge_amount: this.amount, 
                describe: this.describes    
            })
            if(res.status == 200) {
                this.amount = ''
                this.describes = ''
                this.getBalance()
                this.getRecharge()
                ElMessage.success(res.message)
            }else {
                ElMessage.error(res.message)
            }
        }
    },
}
</script>

<style lang="less" scoped>
    .phone {
        .bigbox {
            width: 96%;
            margin: auto;
            .titles {
                font-size: 24px;
                font-family: Arial;
                font-weight: 600;
                text-align: left;
                line-height: 80px;
            }

            .moneybox {
                width: 100%;
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                

                .leftmoney {
                    background-image: url('../../assets/img/walletbg-01.png');
                    width: 100%;
                    height: 320px;
                    background-size: cover;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100px;
                        height: 100px;
                    }

                    .jinebox {
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;
                        span:nth-child(1) {
                            font-size: 40px;
                            font-family: Arial;
                            font-weight: bold;
                            color: #ffffff;
                        }

                        span:nth-child(2) {
                            font-size: 22px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }

                .rightmoney {
                    width: 100%;
                    height: 320px;
                    background-size: cover;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    background: #fff;
                    margin-top: 20px;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                    .righttext {
                        font-size: 22px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 20px;
                    }
                }
            }

            .walletbsbg {
                width: 100%;
                background: #fff;
                margin-top: 30px;

                .walletbox {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    width: 100%;
                    line-height: 80px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                    border-bottom: 1px solid #cacaca;
                }

                .tabbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-top: 20px;
                    .itemtable {
                        font-size: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 10px;
                        .neir {
                            width: 75%;
                            text-align: right;
                            .span2{
                                display: inline-block;
                                font-size: 24px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #ffffff;
                                background: #22ac38;
                                border-radius: 5px;
                                padding: 5px 15px;
                            }
                        }
                    }
                }
            }
        }

        .paytc {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;

            .tchezi {
                width: 90%;
                height: 650px;
                overflow: auto;
                margin: auto;
                margin-top: 20%;
                background: #fff;
                border-radius: 5px;
                box-sizing: 15px;
                padding: 0 15px;

                .paytitle {
                    width: 100%;
                    height: 47px;
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 500;
                    color: #000000;
                    line-height: 47px;
                    text-align: left;
                    border-bottom: 1px solid #dcdcdc;
                }

                .payselectbox {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    padding: 20px;
                    .payselect {
                        width: 180px;
                        height: auto;
                        border: 2px solid #CACACA;
                        border-radius: 5px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 10px;
                        padding: 20px 0px;
                        cursor: pointer;
                        img {
                            width: 140px;
                            height: auto;
                            margin-top: 10px;
                        }
                    }
                    .dongtai {
                        border: 2px solid #00adf1;
                    }
                }

                .zhifuma {
                    width: 100%;
                    border: 2px solid #dcdcdc;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 30px 15px;

                    .adresbox {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 24px;
                        span:nth-child(2) {
                            padding: 5px 10px;
                            background: #00adf1;
                            border-radius: 5px;
                            line-height: 35px;
                            color: #fff;
                            margin: 0 5px;
                        }

                        span:nth-child(3) {
                            width: 70%;
                            height: 50px;
                            border: 1px solid #dcdcdc;
                            border-radius: 5px;
                            line-height: 50px;
                            box-sizing: border-box;
                            padding-left: 30px;
                            text-align: left;
                            color: #969696;
                            overflow: hidden;
                        }
                    }

                    .qrcode {
                        margin-top: 35px;

                        .qrtext {
                            display: block;
                            width: 100%;
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 30px;
                            text-align: left;
                        }

                        .ewmbox {
                            width: 70%;
                            margin: auto;

                            .erweima {
                                &>img {
                                    width: 100%;
                                    border-radius: 15px;
                                    overflow: hidden;
                                    margin-top: 50px;
                                }
                            }

                            .addrbox {
                                display: flex;
                                flex-direction: column;
                                font-size: 26px;
                                font-family: Arial;
                                font-weight: 400;
                                margin-top: 30px;
                                span:nth-child(2) {
                                    color: #969696;
                                    height: 60px;
                                    width: 100%;
                                    word-wrap:break-word; 
                                    word-break:break-all; 
                                    overflow: hidden;
                                }
                            }
                        }

                        .miaosu {
                            display: flex;
                            justify-content: space-between;
                            display: flex;
                            align-items: center;
                            margin-top: 40px;
                            font-size: 24px;

                            input {
                                width: 75%;
                                height: 80px;
                                border: 2px solid #dcdcdc;
                                border-radius: 5px;
                                outline: none;
                                box-sizing: border-box;
                                padding-left: 20px;
                                font-size: 24px;
                            }
                        }
                    }
                }

                .amountbox {
                    width: 100%;
                    height: 100px;
                    border: 1px solid #dcdcdc;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding: 0 15px;
                    font-size: 24px;

                    span:nth-child(2) {
                        color: #ff0000;
                    }

                    input {
                        width: 75%;
                        height: 80px;
                        border: 2px solid #dcdcdc;
                        border-radius: 5px;
                        outline: none;
                        box-sizing: border-box;
                        padding-left: 20px;
                        font-size: 24px;
                    }
                }

                .qrbtn {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .querenbox {
                        width: 240px;
                        height: 60px;
                        background: #00adf1;
                        border-radius: 5px;
                        line-height: 60px;
                        text-align: center;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }


</style>