<template>
  <!-- 手机端 -->
  <div class="phone">
    <!-- 头部 -->
    <div class="topbigbox">
      <div class="topnav">
        <div class="yuyan">
          <div class="navlang" @click="yuyanshow = !yuyanshow">
            <img class="img1" :src="language_img" mode="widthFix" />
            <text>{{ language_name }}</text>
            <img class="img2" src="../../assets/img/xiajiantou.png" mode="widthFix" />
          </div>
          <div class="xzyuyan" v-if="yuyanshow">
            <div v-for="(item, index) in languageList" :key="index">
              <div class="itemyuyan" @click="languageChange(item)">
                <img :src="item.language_img" />
                <span>{{ item.language_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="serchbigbox">
        <div class="serchlogo">
          <!-- logo -->
          <div class="leftlogo">
            <img :src="footInfos.logo" mode="widthFix" @click="toindex" />
          </div>
          <!-- 搜索 -->
          <div class="centserch">
            <!-- 搜索框 -->
            <div class="inputbox">
              <input type="text" :placeholder="$t('buyer.home.enterYourKeyword')" @input="inputChange" @blur="blurChange" v-model="keyword" />
              <div class="sousuo">
                <img src="../../assets/img/fangdajing-bai.png" mode="widthFix" />
              </div>
              <div class="inpchange" v-if="inputshow">
                <div class="titlebox">
                  <span>{{ $t('buyer.shop.shop') }}</span>
                </div>
                <div class="shopbox">
                  <div v-for="item in userList" :key="item.id">
                    <div class="itemboxs" @click="toSellerHome(item.id)">
                      <img :src="item.shop_logo" />
                      <div class="shops">
                        <span>{{ item.shop_name }}</span>
                        <span>{{ item.meta_description }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbox">
        <router-link to="/index/centpage">{{ $t('button.button.home') }}</router-link>
        <router-link to="/index/allcategories">{{ $t('button.button.categories') }}</router-link>
      </div>
    </div>

    <router-view></router-view>

    <!-- 底部 -->
    <div class="bombsbeijing">
      <div class="sibox">
        <div class="itemxx" @click="towzdetail(1)">
          <img src="../../assets/img/index-botm-01.png" />
          <text>{{ $t('button.button.termsConditions') }}</text>
        </div>
        <div class="itemxx" @click="towzdetail(2)">
          <img src="../../assets/img/index-botm-02.png" />
          <text>{{ $t('button.button.returnPolicy') }}</text>
        </div>
        <div class="itemxx" @click="towzdetail(3)">
          <img src="../../assets/img/index-botm-03.png" />
          <text>{{ $t('button.button.supportPolicy') }}</text>
        </div>
        <div class="itemxx" @click="towzdetail(4)">
          <img src="../../assets/img/index-botm-04.png" />
          <text>{{ $t('button.button.privacyPolicy') }}</text>
        </div>
      </div>
    </div>
    <div class="botmbig">
      <div class="botm">
        <div class="botmleft">
          <img :src="footInfos.logo" @click="toindex" />
          <text>{{ footInfos.website_title }}</text>
          <div class="appbox">
            <img src="../../assets/img/googlepay.png" mode="widthFix" @click="appdownload" />
            <img src="../../assets/img/appstore.png" mode="widthFix" @click="iosdownload" />
          </div>
        </div>
        <div class="botmright">
          <div class="onebox">
            <div class="infotext">
              <text>{{ $t('buyer.home.contactInfo') }}</text>
            </div>
            <div class="mall">
              <text>Telegram:</text>
              <text>{{ footInfos.telegram }}</text>
            </div>
            <div class="mall">
              <text>WhatsApp:</text>
              <text>{{ footInfos.wechat }}</text>
            </div>
            <div class="mall">
              <text>Email:</text>
              <text>{{ footInfos.website_email }}</text>
            </div>
          </div>
          <div class="twobox">
            <div class="account">
              <text>{{ $t('buyer.home.myAccount') }}</text>
            </div>
            <div class="wenzibox">
              <text>{{ $t('buyer.home.login') }}</text>
              <text>{{ $t('buyer.home.orderHistory') }}</text>
              <text>{{ $t('buyer.home.myWishlist') }}</text>
              <text>{{ $t('buyer.home.trackOrder') }}</text>
              <text>Be an affiliate partner</text>
            </div>
            <div class="seller">
              <text>{{ $t('buyer.home.beASeller') }}</text>
            </div>
            <div class="nowa" @click="toSignup">
              <text>{{ $t('buyer.home.applyNow') }}</text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dibubig">
      <div class="dibu">
        <div class="leftwenz">
          <text>{{ footInfos.website_icp }}</text>
        </div>
        <div class="centimg">
          <img src="../../assets/img/index-botm-05.png" mode="" />
          <img src="../../assets/img/index-botm-06.png" mode="" />
          <img src="../../assets/img/index-botm-07.png" mode="" />
          <img src="../../assets/img/index-botm-08.png" mode="" />
          <img src="../../assets/img/index-botm-09.png" mode="" />
        </div>
        <div class="rightyhimg">
          <img src="../../assets/img/index-botm-10.png" mode="" />
        </div>
      </div>
    </div>
    <tab-bar v-if="tabBarShow == 1"></tab-bar>
    <tab-bar-seller v-if="tabBarShow == 2"></tab-bar-seller>
  </div>
</template>


<script>
import { pCate, search, memberCount } from '../../api/buyerapi.js'
import { language, footInfo } from '../../api/api.js'
import { ElMessage } from 'element-plus'
import TabBar from '@/components/TabBar.vue'
import TabBarSeller from '@/components/tabBarSeller.vue'

export default {
  components: {
    TabBar,
    TabBarSeller,
  },
  data() {
    return {
      active: 1,
      logTrue: false, //是否登陆
      shows: false,
      show: false,
      inputshow: false, //搜索框输入显示
      yuyanshow: false, //语言选择
      mypanelshow: false, //显示个人管理页面
      CateList: [], //弹窗下拉选择列表数据
      languageList: [], //语言列表
      language_img: '', //语言图标
      language_name: '', //语种
      footInfos: [], //页脚信息
      loginshow: '', //是否登录
      keyword: '', //搜索输入关键字
      userList: [], //搜索的店铺列表
      cartCount: '', //购物车数量
      collectCount: '', //收藏数量
      tabBarShow: 1, //显示哪个tabbar
    }
  },
  mounted() {
    let _this = this
    _this.getPagt()
    _this.getpCate()
    _this.getLanguage()
    _this.getFoot()
    const mypanel = sessionStorage.getItem('member_role')
    if (mypanel == 1) {
      this.mypanelshow = true
    } else {
      this.mypanelshow = false
    }
    if (sessionStorage.getItem('member_role')) {
      this.logTrue = true
      this.getCartNumber()
      if (sessionStorage.getItem('member_role') == 2) {
        this.tabBarShow = 2
      } else {
        this.tabBarShow = 1
      }
    } else {
      this.logTrue = false
      this.tabBarShow = 1
    }
  },
  watch: {
    $route: function (r) {
      if (r.path == '/index/centpage' || r.path == '/' || r.path == '/index') {
        this.show = false
        this.shows = false
      } else {
        this.show = true
      }
      if (sessionStorage.getItem('member_token')) {
        this.loginshow = true
      } else {
        this.loginshow = false
      }
      if (sessionStorage.getItem('member_role') == 1) {
        this.mypanelshow = true
      } else {
        this.mypanelshow = false
      }
    },
  },
  methods: {
    // 语言切换事件
    languageChange(e) {
      this.yuyanshow = false
      this.language_img = e.language_img
      this.language_name = e.language_name
      sessionStorage.setItem('language', e.values)
      sessionStorage.setItem('language_name', e.language_name)
      sessionStorage.setItem('language_img', e.language_img)
      this.$router.go(0)
      this.$i18n.locale = sessionStorage.getItem('language')
    },
    // 获取选择列表数据
    async getpCate() {
      const { data: res } = await pCate()
      if (res.status == 200) {
        this.CateList = res.success
      } else {
        ElMessage.error(res.message)
      }
      // console.log(res)
    },
    // 获取购物车和收藏
    async getCartNumber() {
      const { data: res } = await memberCount()
      if (res.status == 200) {
        this.cartCount = res.success.cart_count
        this.collectCount = res.success.collect_count
      } else {
        ElMessage.error(res.message)
      }
      console.log('数量', res)
    },
    // 获取页面页脚信息
    async getFoot() {
      const { data: res } = await footInfo()
      if (res.status == 200) {
        this.footInfos = res.success
      } else {
        ElMessage.error(res.message)
      }
      let script = document.createElement('script')
      script.src = this.footInfos.website_statics_script
      script.async = true
      document.body.appendChild(script)
      console.log(res)
    },
    // 获取语言
    async getLanguage() {
      const { data: res } = await language()
      if (res.status == 200) {
        this.languageList = res.success
        if (sessionStorage.getItem('language')) {
          this.language_img = sessionStorage.getItem('language_img')
          this.language_name = sessionStorage.getItem('language_name')
        } else {
          this.language_img = res.success[0].language_img
          this.language_name = res.success[0].language_name
        }
      } else {
        ElMessage.error(res.message)
      }
      // console.log(res)
    },
    // 退出登录
    logout() {
      sessionStorage.removeItem('member_token')
      sessionStorage.removeItem('member_role')
      sessionStorage.removeItem('language')
      sessionStorage.removeItem('language_name')
      sessionStorage.removeItem('language_img')
      this.$router.go(0)
    },
    // 路由变化事件
    getPagt() {
      if (this.$route.path == '/index/centpage') {
        this.show = false
        this.shows = false
      } else {
        this.show = true
      }
      if (sessionStorage.getItem('member_token')) {
        this.loginshow = true
      } else {
        this.loginshow = false
      }
    },
    // 搜索框输入事件
    async inputChange() {
      const { data: res } = await search({
        keyword: this.keyword, //关键字
      })
      if (res.status == 200) {
        this.userList = res.success
      }
      this.inputshow = true
    },
    // 搜索框失去焦点事件
    blurChange() {
      setTimeout(() => {
        this.inputshow = false
      }, 200)
    },
    // 去登陆
    toLogin() {
      this.$router.push('/index/login')
    },
    // 去分类页面
    toCategorieyDetail() {
      this.shows = false
      this.$router.push('/index/categorieydetail')
    },
    // // 去我的主页
    // toMyPanel() {
    //     this.$router.push('/index/mypage')
    // },
    // 去首页
    toindex() {
      this.$router.push('/index/centpage')
      window.scrollTo(0, 0)
    },
    // 去购物车
    toCart() {
      this.$router.push('/index/cart')
    },
    // 退货政策
    towzdetail(id) {
      this.$router.push({ path: '/index/returnspolicy', query: { id: id } })
    },
    // 去注册成为卖家
    toSignup() {
      this.$router.push('/index/sellersignup')
      window.scrollTo(0, 0)
    },
    // 去卖家主页
    toSellerHome(id) {
      this.$router.push({ path: '/index/storehome', query: { id: id } })
      this.keyword = ''
    },
    // 买家注册
    toSignups() {
      this.$router.push('/index/signup')
    },
    // 去收藏列表
    toWishlist() {
      this.$router.push('/index/mypage/wishlist')
    },
    appdownload() {
      window.location.href = 'https://lianbang-shopping.com/chadstone-mall.apk'
    },
    iosdownload() {
      window.location.href = 'https://lianbang-shopping.com/chadstone-mall.mobileconfig'
    },
  },
}
</script>

<style scoped lang="less">
.router-link-active {
  color: #00adf1;
  font-weight: 600;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: #000;
}

.phone {
  :deep(.el-message_icon) {
    font-size: 32px !important;
  }
  :deep(.el-icon-success) {
    font-size: 32px !important;
  }
  :deep(.el-message .el-message__content) {
    font-size: 32px !important;
  }

  .topbigbox {
    width: 100%;
    .topnav {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      // 语言选择
      .yuyan {
        position: relative;
        left: 15px;
        font-size: 24px;
        .navlang {
          display: flex;
          align-items: center;

          .img1 {
            width: 40px;
            height: auto;
            margin-right: 10px;
          }

          .img2 {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-left: 3px;
          }
        }

        .xzyuyan {
          width: 300px;
          position: absolute;
          z-index: 999;
          top: 40px;
          left: 15px;
          border-radius: 5px;
          overflow: hidden;
          border: solid 1px #e6e6e6;

          .itemyuyan:hover {
            background: #00adf1;
            color: #fff;
          }

          .itemyuyan {
            background: #fff;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding-left: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 30px;
              height: 20px;
              margin-right: 15px;
            }

            font-size: 24px;
            color: #969696;
          }
        }
      }
    }

    .serchbigbox {
      background-color: #fff;
      width: 100%;

      .serchlogo {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 15px;
        .leftlogo {
          img {
            width: 200px;
            height: auto;
          }
        }

        .centserch {
          width: 60%;
          height: 60px;
          display: flex;
          justify-content: space-between;
          .inputbox {
            border: solid 1px #00adf1;
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: relative;
            input {
              width: 90%;
              height: 100%;
              box-sizing: border-box;
              padding-left: 15px;
              border: none;
              outline: none;
              font-size: 24px;
            }

            .sousuo {
              width: 16%;
              height: 100%;
              background-color: #00adf1;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;

              img {
                width: 35px;
                height: 35px;
              }
            }

            .inpchange {
              width: 100%;
              border: solid 1px #dcdcdc;
              position: absolute;
              top: 50px;
              left: 0;
              z-index: 999;
              border-radius: 5px;
              background: #fff;

              .titlebox {
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 20px;
                font-size: 24px;
                background: #eef0f2;
                color: #969696;
              }

              .shopbox {
                width: 100%;
                box-sizing: border-box;
                padding: 0 10px;

                .itemboxs {
                  display: flex;
                  align-items: center;
                  height: 60px;

                  img {
                    width: 50px;
                    height: 50px;
                  }

                  .shops {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    line-height: 25px;
                    margin-left: 10px;
                    font-size: 24px;

                    span:nth-child(1):hover {
                      color: #00adf1;
                    }

                    span:nth-child(2) {
                      font-size: 14px;
                      color: #969696;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .navbox {
      font-size: 28px;
      font-family: Arial;
      color: #00adf1;
      color: #000;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      font-weight: 600;

      a {
        text-align: center;
        padding: 0 15px;
      }

      .activeColor {
        color: #00adf1;
      }
    }
  }

  .bombsbeijing {
    width: 100%;
    background-color: #fff;
    margin-top: 30px;

    .sibox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: auto;

      .itemxx {
        display: flex;
        height: 180px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }

        & > text {
          font-size: 24px;
          font-family: Arial;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }

  .botmbig {
    width: 100%;
    background: #191b1e;

    .botm {
      width: 100%;
      margin: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .botmleft {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 50px;
        & > img {
          width: 320px;
          height: auto;
        }
        > text {
          font-size: 24px;
          font-family: Arial;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 20px;
          margin-top: 25px;
        }
        .appbox {
          img {
            width: 240px;
          }

          img:nth-child(1) {
            margin-right: 15px;
          }
        }
      }

      .botmright {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .onebox {
          .infotext {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 30px;
            border-bottom: 1px solid #555963;
            padding-bottom: 10px;
          }

          .mall {
            margin: 20px 0px;
            & > text {
              display: block;
              color: #fff;
            }

            text:nth-child(1) {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #adadad;
            }

            text:nth-child(2) {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }

        .twobox {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .account {
            width: 100%;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            border-bottom: 1px solid #555963;
            padding: 30px 0px;
          }

          .wenzibox {
            margin-top: 15px;
            width: 100%;

            & > text {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 60px;
              display: block;
            }
          }

          .seller {
            width: 100%;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 30px;
            border-bottom: 1px solid #555963;
            padding-bottom: 20px;
          }

          .nowa {
            margin: 20px 0px;
            padding: 4px 15px;
            background: #00adf1;
            border-radius: 5px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 60px;
          }
        }
      }
    }
  }

  .dibubig {
    width: 100%;
    background-color: #0b0b0b;
    margin-bottom: 80px;
    .dibu {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .leftwenz {
        padding: 20px 0;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .centimg {
        padding: 20px 0;
        display: flex;
        img {
          width: 50px;
          height: 50px;
          margin: 0 10px;
        }
      }

      .rightyhimg {
        padding: 20px 0;
        margin-bottom: 20px;
        display: flex;
        img {
          width: 500px;
          height: auto;
        }
      }
    }
  }
  .buttonbox {
    width: 100%;
    height: 100px;
    background: #fff;
    border-top: solid 1px #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;
    .itembtn {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 25%;

      font-size: 28px;
    }
    .clickactive {
      color: #00adf1 !important;
    }
  }
}
</style>
