<template>
    <div class="phone">
        <div class="bigbox">
            <div class="orderbox">
                <div class="title">
                    <span>{{ $t('seller.home.order') }}</span>
                    <div class="rightxz">
                        <el-select v-model="payValue" class="m-2" :placeholder="$t('seller.order.filterByPaymentStatus')" size="large" @change="payChange">
                            <el-option
                            style="font-size: 20px;height: 60px;"
                            v-for="item in payStatus"
                            :key="item.value"
                            :label="item.names"
                            :value="item.value"
                            />
                        </el-select>
                        <el-select v-model="deliverValue" class="m-2" :placeholder="$t('seller.order.filterByDeliveryStatus')" size="large" @change="deliverChange">
                            <el-option
                            style="font-size: 20px; height: 60px;"
                            v-for="item in deliverStatus"
                            :key="item.value"
                            :label="item.names"
                            :value="item.value"
                            />
                        </el-select>
                        <input type="text" :placeholder="$t('seller.order.typeOrderCode')" v-model="keyWord" @input="inputChange">
                    </div>
                </div>
                <div class="tabbox">
                    <el-table :data="orderData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="order_no" :label="$t('seller.orderDetail.orderCode')" width="210">
                            <template #default="scope">
                                <p>{{ scope.row.order_no }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="order_goods_count" :label="$t('seller.order.numOfProducts')" width="130">
                            <template #default="scope">
                                <p>{{ scope.row.order_goods_count }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="member_name" :label="$t('seller.order.customer')" width="170">
                            <template #default="scope">
                                <p>{{ scope.row.member_name }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" :label="$t('seller.order.total')" width="110">
                            <template #default="scope">
                                <p>{{ scope.row.amount }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="seller_frozen_amount" :label="$t('seller.order.earning')" width="110">
                            <template #default="scope">
                                <p>{{ scope.row.seller_frozen_amount }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="delivery_status" :label="$t('seller.order.deliveryStatus')" width="130">
                            <template #default="scope">
                                <p>{{ scope.row.delivery_status }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="seller_fa_status" :label="$t('seller.order.fundingStatus')" width="130">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.seller_fa_status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="buyer_pay" :label="$t('seller.order.paymentStatus')" width="160">
                            <template #default="scope">
                                <span class="span2">{{ scope.row.buyer_pay }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="seller_pay" :label="$t('seller.order.sellerBuyStatus')" width="210">
                            <template #default="scope">
                                <span class="span2" :class="scope.row.procurement_button == 1? 'span3': ''" @click="caigou(scope.row.id)">{{ scope.row.seller_pay }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" :label="$t('seller.moneyWithdraw.message')" width="210">
                            <template #default="scope">
                                <span class="span1 spans" @click="tcshowChnage(scope.row.id)">{{ $t('seller.order.contactBuyer') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('seller.myLoan.options')" width="140">
                            <template #default="scope">
                                <img src="../../../assets/img/history-yanjing.png" @click="toDetails(scope.row.id)">
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background 
                    class="my-pagination"
                    layout="prev, pager, next" 
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <el-dialog v-model="dialogshow" :title="$t('seller.confirmPay.confirmPay')" width="60%">
                <div class="dialogbox">
                    <span>{{ $t('seller.confirmPay.doYouReallyWantToPay') }}?</span>
                    <div class="btnboxs">
                        <el-button @click="dialogshow = false">{{ $t('buyer.cart.cancel') }}</el-button>
                        <el-button type="primary" @click="confirmCaigou">{{ $t('seller.confirmPay.pay') }}</el-button>
                    </div>
                </div>
            </el-dialog>
            <!-- 弹窗 -->
            <div class="zzbox" v-if="tcshow">
                <div class="tcbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.anyQueryAboutThisOrder.anyQueryAboutThisOrder') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="gbtcshow">
                    </div>
                    <div class="inpbox">
                        <input type="text" placeholder="Title" v-model="titles">
                        <textarea :placeholder="$t('seller.anyQueryAboutThisOrder.yourQuestion')" v-model="content"></textarea>
                    </div>
                    <div class="btnbox">
                        <span @click="gbtcshow">{{ $t('buyer.cart.cancel') }}</span>
                        <span @click="contactBuyerChange">{{ $t('seller.withdrawToWallet.send') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {orderList,contactBuyer,orderPayment,orderPay,deliverStatus} from "@/api/sellerapi"
    import { ElMessage} from 'element-plus'
    export default {
        data(){
            return {
                id: '', //订单ID
                tcshow: false,
                page: 1, //页码
                count: 0, //总条数
                orderData: [],//订单数据
                payStatus: [], //支付状态选项
                deliverStatus: [], //交付状态选项
                content: '', //联系买家-内容
                titles: '',//联系买家-标题
                dialogshow: false, //采购对话框
                payValue: 0, //支付状态选择的值
                deliverValue: 0, //交付状态选择的值
                keyWord: '',
            }
        },
        mounted(){
            this.getOrdersList()
            this.getPayStatus()
            this.getDeliverStatus()
        },
        methods: {
            // 查看订单详情
            toDetails(id){
                this.$router.push({path: '/Home/SellerOrderDetails',query:{id:id}})
            },
            // 获取订单列表
            async getOrdersList(){
                const {data: res} = await orderList({
                    "pay_status": this.payValue, //支付状态（支付选项选择的value值）
                    "delivery_status": this.deliverValue, //交付状态(交付选项选择的value值)
                    "order_keywords": this.keyWord, //订单编号关键字搜索
                    "page": this.page //页码
                })
                if(res.status == 200) {
                    this.orderData = res.success.list
                    this.count = parseInt(res.success.count)
                    this.page = res.success.page
                    console.log('order',res)
                }
            },
            // 获取支付状态下拉选项
            async getPayStatus(){
                const {data: res} = await orderPay()
                if(res.status == 200) {
                    this.payStatus = res.success
                }else {
                    ElMessage.error(res.message)
                }
                console.log('支付状态',res)
            },
            // 获取支付状态下拉选项
            async getDeliverStatus(){
                const {data: res} = await deliverStatus()
                if(res.status == 200) {
                    this.deliverStatus = res.success
                }else {
                    ElMessage.error(res.message)
                }
                console.log('交付状态',res)
            },
            // 显示会话弹窗
            tcshowChnage(id){
                this.tcshow = true
                this.id = id
            },
            // 关闭会话弹窗
            gbtcshow(){
                this.tcshow = false
                this.titles = ''
                this.content = ''
            },
            // 联系买家
            async contactBuyerChange(){
                const {data: res} = await contactBuyer({
                    id: this.id, //订单id
                    title: this.titles, //会话标题
                    content: this.content //会话内容
                })
                this.tcshow = false
                this.titles = ''
                this.content = ''
                if(res.status == 200) {
                    ElMessage.success(res.message)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 采购弹窗
            caigou(id){
                this.dialogshow = true
                this.id = id
            },
            // 确认采购
            async confirmCaigou(){
                this.dialogshow = false
                const {data: res} = await orderPayment({
                    id: this.id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getOrdersList()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 上一页
			prevChange(e){
				this.page = e
				this.getOrdersList()
			},
			// 下一页
			nextChange(e){
				this.page = e
				this.getOrdersList()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
				this.getOrdersList()
			},
            // 支付状态下拉事件
            payChange(e){
                this.payValue = e
                this.getOrdersList()
            },
            // 交付状态下拉事件
            deliverChange(e){
                this.deliverValue = e
                this.getOrdersList()
            },
            inputChange(){
                this.getOrdersList()
            }
        }
    }
</script>

<style lang="less" scoped>
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .orderbox {
                width: 100%;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .title {
                    width: 100%;
                    border-bottom: 1px solid #DFDFDF;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 20px;
                    span {
                        font-size: 24px;
                        line-height: 60px;
                    }
                    .rightxz {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        :deep(.el-select){
                            width: 100%;
                            height: 60px;
                            margin-top: 20px;
                            font-size: 20px;
                        }
                        :deep(.el-input__wrapper) {
                            height: 60px;
                            font-size: 20px;
                        }
                        input {
                            outline: none;
                            width: 100%;
                            height: 60px;
                            background: #FFFFFF;
                            border: 2px solid #DFDFDF;
                            border-radius: 5px;
                            font-size: 20px;
                            box-sizing: border-box;
                            padding-left: 10px;
                            margin-top: 20px;
                        }
                    }
                }
                .tabbox {
                    width: 100%;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .span1 {
                        display: inline-block;
                        background: #25BCF1;
                        border-radius: 4px;
                        padding: 5px 10px;
                        color: #FFFFFF;
                    }
                    .span2 {
                        display: inline-block;
                        background: #0ABB75;
                        border-radius: 4px;
                        padding: 5px 10px;
                        color: #FFFFFF;
                    }
                    .span3 {
                        display: inline-block;
                        background: #0277bd;
                        border-radius: 4px;
                        padding: 5px 10px;
                        color: #FFFFFF;
                    }
                    .spans {
                        cursor: pointer;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                        margin-right:10px;
                        cursor: pointer;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 24px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 24px !important;
                    }
                }
            }
            :deep(.el-dialog__header) {
                font-size: 24px !important;
            }
            :deep(.el-dialog__title) {
                font-size: 24px !important;
            }
            .dialogbox {
                min-height: 100px;
                span {
                    display: block;
                    font-size: 24px;
                    height: 80px;
                }
                .btnboxs {
                    display: flex;
                    justify-content: center;
                    button {
                        margin: 0 20px;
                        width: 180px;
                        height: 60px;
                        font-size: 20px;
                    }
                }
            }
            .zzbox {
                width: 100%;
                height: 100%;
                position: fixed;
                z-index: 999;
                background: rgba(0, 0, 0, 0.5);
                top: 0;
                left: 0;
                .tcbox {
                    width: 80%;
                    height: 520px;
                    background: #FFFFFF;
                    border-radius: 5px; 
                    margin: auto;
                    margin-top: 20%;
                    .titlebox {
                        width: 100%;
                        height: 80px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 35px;
                        border-bottom: solid 2px #DFDFDF;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .inpbox {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 35px;
                        input {
                            width: 100%;
                            height: 80px;
                            background: #FFFFFF;
                            border: 2px solid #EDEDED;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding-left: 10px;
                            font-size: 20px;
                            margin-top: 20px;
                        }
                        textarea {
                            width: 100%;
                            height: 200px;
                            background: #FFFFFF;
                            border: 2px solid #EDEDED;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding: 10px;
                            font-size: 20px;
                            resize: none;
                            margin-top: 20px;
                        }
                    }
                    .btnbox {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        padding: 0 35px;
                        margin-top: 20px;
                        span {
                            width: 200px;
                            height: 70px;
                            border: 2px solid #00ADF1;
                            border-radius: 4px;
                            font-size: 20px;
                            text-align: center;
                            line-height: 70px;
                            cursor: pointer;
                        }
                        span:nth-child(1){
                            color: #00ADF1;
                        }
                        span:nth-child(2){
                            color: #FFFFFF;
                            background: #00ADF1;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
</style>