<template>
    <div class="bigbox">
        <div class="title">
            <span>Support Ticket</span>
        </div>
        <div class="cjticket" @click="show = true">
            <span>+</span>
            <span>Create a Ticket</span>
            <span>+</span>
        </div>
        <div class="tickets">
            <div class="ticbox">
                <span>Tickets</span>
            </div>
            <div class="tablebox">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="ticketid" label="Ticket ID " width="180" />
                    <el-table-column prop="sendingdate" label="Sending Date " width="240" />
                    <el-table-column prop="subject" label="Subject" width="200"/>
                    <el-table-column prop="status" label="Status">
                        <template #default="scope">
                            <span class="span1">{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="options" :label="$t('seller.myLoan.options')">
                        <template #default="scope">
                            <span class="span2" @click="toTicketDetail(scope.row)">{{ scope.row.options }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="fenye">
                <el-pagination background layout="prev, pager, next" :total="1000" />
            </div>
        </div>
        <!-- 上传弹窗 -->
        <div class="tcbox" v-if="show">
            <div class="bsbgbox">
                <div class="createbox">
                    <span>Create a Ticket</span>
                    <img src="../../assets/img/cuowu-hui.png" @click="show = false" >
                </div>
                <div class="subjectbox">
                    <span>Subject</span>
                    <input type="text" placeholder="Subject">
                </div>
                <div class="reply">
                    <span>Provide a detailed description</span>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="flieup">
                    <span>Photo</span>
                    <el-upload
                        ref="upload"
                        v-model:file-list="fileList"
                        class="upload-demo"
                        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                        multiple
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :limit="3"
                        :on-exceed="handleExceed"
                        :auto-upload="false"
                    >
                        <template #trigger>
                            <div class="browse">
                                <span>Browse</span>
                                <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                            </div>
                        </template>
                    </el-upload>
                </div>
                <div class="btnbox">
                    <span>Cancel</span>
                    <span>Send Ticket</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                show: false, //弹窗显示
                tableData: [
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                    { ticketid: '#2147483647',sendingdate: '2023-06-30 16:56:09',subject: '仨的阿瑟',status: 'Solved',options: 'View Details'},
                ]
            }
        },
        methods: {
            // 去详情页查看
            toTicketDetail(e){
                this.$router.push('/index/mypage/supportticketdetail')
            }
        }
    }
</script>

<style scoped lang="less">

</style>