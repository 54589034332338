<template>
    <div class="allbox">
        <div class="titles">
            <span>All uploaded files</span>
            <span @click="toUploadNewFile">Upload New File</span>
        </div>
        <div class="allfiles">
            <div class="filetitle">
                <div class="leftwz">
                    <span>All files</span>
                </div>
                <div class="rightbox">
                    <el-select v-model="value" class="m-2" placeholder="Select" size="large">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                    <input type="text" placeholder="Search your files">
                    <span class="span1">Search</span>
                </div>
            </div>
            <div class="imgbigbox">
                <div class="imgbox" v-for="(item,index) in 10" :key="index">
                    <img class="gdimg" src="../../../assets/img/shouji.png">
                    <span class="diandian" @click="tcshow = !tcshow">...</span>
                    <span>工单支持.png</span>
                    <span class="colorhui">170KB</span>
                    <div class="tcbox" v-if="tcshow">   
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-details-hui.png">
                            <span>Details Info</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-xiazhai-hui.png">
                            <span>Download</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-copy-hui.png">
                            <span>Copy Link</span>
                        </div>
                        <div class="itemtc">
                            <img src="../../../assets/img/seller-shanchu-hui.png">
                            <span>Delete</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                tcshow: false, //点击图片弹窗显示
                options: [
                    {
                        value: 'Option1',
                        label: 'Option1',
                    },
                    {
                        value: 'Option2',
                        label: 'Option2',
                    },
                    {
                        value: 'Option3',
                        label: 'Option3',
                    },
                    {
                        value: 'Option4',
                        label: 'Option4',
                    },
                    {
                        value: 'Option5',
                        label: 'Option5',
                    }, 
                ]
            }
        },
        methods: {
            toUploadNewFile(){
                this.$router.push('/Home/SellerUploadNewFile')
            }
        }
    }
</script>

<style lang="less" scoped>
</style>