<template>
    <div class="phone">
        <div class="bigbox">
            <div class="allbox">
                <div class="titles">
                    <span>{{ $t('seller.product.allInHouseProduct') }}</span>
                    <div class="titleright">
                        <el-select v-model="cateId" class="m-2" size="large" @change="optionChange">
                            <el-option
                            style="font-size: 24px;height: 60px;"
                            v-for="item in cateList"
                            :key="item.cate_id"
                            :label="item.name"
                            :value="item.cate_id"
                            />
                        </el-select>
                        <input type="text" @input="inputChnage">
                        <span>{{ $t('seller.product.search') }}</span>
                    </div>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="main_img" :label="$t('seller.copyNewProduct.name')" width="100">
                            <template #default="scope">
                                <img class="imgname" :src="scope.row.main_img">
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_name" label="" width="460" />
                        <el-table-column prop="info" :label="$t('seller.product.info')" width="300">
                            <template #default="scope">
                                <div class="infobox">
                                    <div class="iteminfo">
                                        <span>Num of Sale:</span>
                                        <span>{{ scope.row.info.order_count }}</span>
                                    </div>
                                    <div class="iteminfo">
                                        <span>Base Price:</span>
                                        <span>{{ scope.row.info.primary }}</span>
                                    </div>
                                    <div class="iteminfo">
                                        <span>Rating:</span>
                                        <span>{{ scope.row.info.rate }}</span>
                                    </div>
                                    <div class="iteminfo">
                                        <span style="color: #EC0000;">Estimated commission:</span>
                                        <span>{{ scope.row.info.goods_commission }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="current_qty" :label="$t('seller.product.totalStock')" width="210" />
                        <el-table-column prop="todaysdeal" :label="$t('seller.product.todayDeal')" width="210">
                            <template #default="scope">
                                <el-switch v-model="scope.row.todaysdeal" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="featured" :label="$t('seller.product.featured')" width="180">
                            <template #default="scope">
                                <el-switch v-model="scope.row.featured" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="options" :label="$t('seller.myLoan.options')" width="180">
                            <template #default="scope">
                                <div class="imgbox">
                                    <img src="../../../assets/img/history-yanjing.png">
                                    <img src="../../../assets/img/seller-fuzhi-cheng.png" @click="copyGoods(scope.row)">
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination
                    style="font-size: 24px !important;"
                    background
                    class="my-pagination"
                    layout="prev, pager, next"
                    :total="count"
                    @prev-click="onPrevClick" 
                    @next-click="onNextClick"
                    @current-change="currentChange"/>
            </div>
        </div>
    </div>
</template>


<script>
    import { ElMessage} from 'element-plus'
    import {sysGoods,sysGoodsCp,cateAll} from '../../../api/sellerapi'
    export default {
        data(){
            return {
                page: 1, //分页
                count: 0, //总条数
                keyword: '',//搜索值
                cateList: [],//下拉列表
                cateId: '', //分类id
                tableData: [],//商品列表
            }
        },
        mounted(){
           this.getsysGoodsList()
           this.getcateAll()
        },
        methods: {
            // 获取平台商品列表
            async getsysGoodsList(){
                const {data: res} = await sysGoods({
                    page: this.page,
                    cate_id: this.cateId,
                    keyword: this.keyword
                })
                if(res.status == 200) {
                    this.tableData = res.success.list
                    this.count = parseInt(res.success.count)
                }else {
                    ElMessage.error(res.message)
                }
                console.log(res)
            },
             // 点击上一页事件
             onPrevClick(e){
                this.page = e
                this.getsysGoodsList()
            },
            // 点击下一页事件
            onNextClick(e){
                this.page = e
                this.getsysGoodsList()
            },
            // 点击页数事件
            currentChange(e){
                this.page = e
                this.getsysGoodsList()
            },
            // 复制产品
            async copyGoods(e){
                const {data: res} = await sysGoodsCp({
                    id: e.id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getsysGoodsList()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 获取选择商品分类
            async getcateAll(){
                const {data: res} = await cateAll()
                if(res.status == 200) {
                    this.cateList = res.success
                } else {
                    console.log(res.message)
                }
                console.log(res)   
            },
            // 下拉事件-分类
            optionChange(e){
                this.cateId = e
                this.getsysGoodsList()
            },
            // 搜索事件
            inputChnage(){
                this.page = 1
                this.getsysGoodsList()
            },
        }
    }
</script>


<style lang="less" scoped>
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .allbox {
                width: 100%;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 30px;
                    .titles {
                        width: 100%;
                        display: flex;
                        min-height: 380px;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        padding: 0 30px;
                        border-bottom: solid 1px #dcdcdc;
                        &>span {
                            display: inline-block;
                            width: 100%;
                            font-size: 24px;
                            font-weight: 600;
                            text-align: left;
                        }
                        .titleright {
                            width: 100%;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            .m-2 {
                                width: 100%;
                                height: 72px !important;
                                border: 1px solid #DFDFDF;
                                overflow: hidden;
                                margin-top: 10px;
                                :deep(.el-input) {
                                    width: 100%;
                                    height: 70px;
                                    font-size: 20px;
                                }
                            }
                            :deep(.el-input__wrapper) {
                                width: 100%;
                                height: 70px;
                            }
                            :deep(.el-input__inner) {
                                font-size: 20px !important;
                            }
                            input {
                                width: 100%;
                                height: 70px;
                                background: #FFFFFF;
                                border: 2px solid #DFDFDF;
                                border-radius: 4px;
                                outline: none;
                                font-size: 20px;
                                box-sizing: border-box;
                                padding-left: 10px;
                                margin-top: 20px;
                                margin-left: 0px;
                            }
                            &>span {
                                width: 120px;
                                height: 60px;
                                background: #0277BD;
                                border-radius: 4px;
                                font-size: 20px;
                                color: #FFFFFF;
                                text-align: center;
                                line-height: 60px;
                                margin-top: 20px;
                            }
                        }
                    }
                    .tabbox {
                        width: 100%;
                        margin-top: 15px;
                        text-align: left;
                        .imgname {
                            width: 80px;
                            height: 80px;
                        }
                        .infobox {
                            font-size: 24px;
                            .iteminfo {
                                span:nth-child(2){
                                    color: #969696;
                                }
                            }
                        }
                        .imgbox {
                            img {
                                width: 40px;
                                height: 40px;
                                margin-right: 10px;
                            }
                        }
                    }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 24px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 24px !important;
                    }
                }
            }
        }
    }
</style>